var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ReloadArrow = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '41', height: '40', viewBox: '0 0 41 40', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M27.6797 11.6016C28.232 11.6016 28.6797 12.0493 28.6797 12.6016V16.2016C28.6797 16.7538 28.232 17.2016 27.6797 17.2016H24.1797C23.6274 17.2016 23.1797 16.7538 23.1797 16.2016C23.1797 15.6493 23.6274 15.2016 24.1797 15.2016H26.6797V12.6016C26.6797 12.0493 27.1274 11.6016 27.6797 11.6016Z', fill: '#7F8897' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11.1797 20C11.1797 15.0477 15.2274 11 20.1797 11C23.554 11 26.5954 12.9088 28.0669 15.7386C28.3217 16.2286 28.131 16.8324 27.641 17.0872C27.151 17.342 26.5473 17.1513 26.2925 16.6614C25.164 14.4912 22.8053 13 20.1797 13C16.332 13 13.1797 16.1523 13.1797 20C13.1797 23.8477 16.332 27 20.1797 27C24.0274 27 27.1797 23.8477 27.1797 20C27.1797 19.4477 27.6274 19 28.1797 19C28.732 19 29.1797 19.4477 29.1797 20C29.1797 24.9523 25.132 29 20.1797 29C15.2274 29 11.1797 24.9523 11.1797 20Z', fill: '#7F8897' }, void 0)] }), void 0) }), void 0));
};
export default ReloadArrow;
