var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var GroupBg = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M6.29067 9.46939L11.7502 11.946C11.9096 12.0185 12.0898 12.0185 12.2492 11.946L17.7087 9.46939C18.0966 9.29338 18.0966 8.70721 17.7087 8.53119L12.2494 6.05432C12.1711 6.01852 12.086 6 11.9999 6C11.9138 6 11.8287 6.01852 11.7504 6.05432L6.29067 8.53096C5.90278 8.70697 5.90278 9.29338 6.29067 9.46939ZM17.709 11.5385L16.3475 10.9213L12.559 12.6386C12.3818 12.719 12.1936 12.7598 11.9998 12.7598C11.806 12.7598 11.618 12.719 11.4406 12.6386L7.65239 10.9213L6.29067 11.5385C5.90278 11.7142 5.90278 12.3002 6.29067 12.476L11.7502 14.9507C11.9096 15.0229 12.0898 15.0229 12.2492 14.9507L17.709 12.476C18.0968 12.3002 18.0968 11.7142 17.709 11.5385ZM17.709 14.5338L16.3526 13.919L12.559 15.6386C12.3818 15.719 12.1936 15.7598 11.9998 15.7598C11.806 15.7598 11.618 15.719 11.4406 15.6386L7.64724 13.919L6.29067 14.5338C5.90278 14.7096 5.90278 15.2955 6.29067 15.4713L11.7502 17.946C11.9096 18.0182 12.0898 18.0182 12.2492 17.946L17.709 15.4713C18.0968 15.2955 18.0968 14.7096 17.709 14.5338Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default GroupBg;
